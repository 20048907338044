import EcomTotalSellsChart from 'components/charts/e-charts/EcomTotalSellsChart';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ReactSelect from 'components/base/ReactSelect';
import { UilMoneyBill } from '@iconscout/react-unicons';
import { useTranslation } from "react-i18next";

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

interface PayoutsHeaderProps {
    payoutList: { key: string; value: string; label:string }[];
    onPayoutChanged?: (value : any) => void;
    // bodyClassName?: string;
    // rowClassName?: string;
    // hasFooter?: boolean;
  }
  
  const PayoutsHeader = ({
    payoutList,
    onPayoutChanged
  }: PayoutsHeaderProps) => {


    const handlePayoutSelected = (value : any) => {
        if(onPayoutChanged){
            isDev ? console.log(value) : null;
            onPayoutChanged(value);
        }
    };

    const { t } = useTranslation();

    const customStyles = {
        container: (base: any) => ({
          ...base,
          width: `370px`
        }),
        control: (base: any) => ({
          ...base,
          // border: 0,
          boxShadow: "none",
          fontSize: '12.3px',
          // border: '1px solid gray !important',
        }),
        menu: (base: any) => ({
          ...base,
          width: "370px",
        })
      };

  return (
    <>
      <Row className="justify-content-between align-items-center mb-5 g-3">
        <Col xs="auto">
          <h4>{t('PayoutsTitle')}</h4>
          <p className="text-body-tertiary lh-sm mb-0">
          {t('PayoutsReceived')}: <strong>€ 0,00</strong> | {t('PayoutsPaymentsPending')}:  <strong>€ 221,22</strong>
          </p>
        </Col>
        <Col xs={12} sm={4}>
        <ReactSelect
                    styles={customStyles}
                    id="payoutList"
                    isClearable
                    // value={selectedCustomer}
                    options={payoutList}
                    handleChanged={handlePayoutSelected}
                    // placeholder={t('Transactions_SelectCustomerPlaceHolder')}
                    placeholder={t('ChoosePayout')}
                    icon={
                      <UilMoneyBill
                        className="react-select-icon text-body-tertiary"
                          size={16}
                        />
                      }
                    />
        </Col>
      </Row>
      {/* <EcomTotalSellsChart /> */}
    </>
  );
};

export default PayoutsHeader;
